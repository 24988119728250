import React from 'react';
import '../components/layout.css';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Footer from '../components/footer';
import ContentFth from '../components/content-fth';
import SEO from '../components/seo';
import GlobalCTA from '../components/globalcta';

export default (props) => {
	const post = props.data.markdownRemark;
	const {
		title,
		date,
		image,
		author,
		extra_img2,
		position,
		extra_img3,
		extra_img4,
		content2,
		content3,
		author_image
	} = post.frontmatter;
	const url = post.fields.slug;
	const textStyle = {
		bold_opacity : {
			fontWeight : 'bold',
			color      : '#707070',
			opacity    : 0.54
		},
		bold         : {
			fontWeight : 'bold',
			color      : '#707070'
		},
		normal       : {
			color   : '#3b3a39',
			opacity : 0.9
		}
	};
	return (
		<Layout getUrl={url}>
			<SEO
				title={`Cortex - ${title}`}
				description={post.excerpt}
				image={() => image && <Img sizes={image.childImageSharp.sizes} />}
			/>
			{image && <Img className='cortex-news-background-new-layout' sizes={image.childImageSharp.sizes} />}
			  <div className='cortex-news-content'>
				<div className='cortex-news-content-title-new-layout'>{title}</div>
				<div className='cortex-news-content-date-new-layout'>{date}</div>
				     <p className='cortex-news-content-content1'>{post.excerpt}</p>
				      <p className='cortex-news-content-content1'>{content2}</p>
				{extra_img2 && <Img className='cortex-news-img-new-layout' sizes={extra_img2.childImageSharp.sizes} />}
				{extra_img3 && <Img className='cortex-news-img-new-layout' sizes={extra_img3.childImageSharp.sizes} />}
				      <p className='cortex-news-content-content1'>{content3}</p>
				{extra_img4 && <Img className='cortex-news-img-new-layout' sizes={extra_img4.childImageSharp.sizes} />}
				 <div id='cortex-news-content-social-auth'>
					{/*  <div id='cortex-news-content-social-auth1'> */}
					{author_image && (
						<Img
							sizes={author_image.childImageSharp.sizes}
							style={{ width: 80, marginTop: '40px ' }}
							alt='ctcEmp'
						/>
					)}
					<div className='cortex-new-content-social-container'>
						<div>
							<span style={textStyle.bold_opacity}>Author</span>
						</div>

						<div>
							<span style={textStyle.bold}>{author}</span>
						</div>

						<div>
							<span style={textStyle.normal}>{position}</span>
						</div>
					</div>
					{/* </div> */}
				</div>
			</div>
			<ContentFth>
				<GlobalCTA />
			</ContentFth>
			<Footer getUrl={url} />
		</Layout>
	);
};

export const pageQuery = graphql`
	query MyQuery($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			excerpt(pruneLength: 2000)
			fields {
				slug
			}
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
				content2
				content3
				author
				position
				author_image {
					publicURL
					childImageSharp {
						sizes(quality: 100) {
							...GatsbyImageSharpSizes
						}
					}
				}
				image {
					publicURL
					childImageSharp {
						sizes(quality: 100) {
							...GatsbyImageSharpSizes
						}
					}
				}
				extra_img2 {
					publicURL
					childImageSharp {
						sizes(quality: 100) {
							...GatsbyImageSharpSizes
						}
					}
				}
				extra_img3 {
					publicURL
					childImageSharp {
						sizes(quality: 100) {
							...GatsbyImageSharpSizes
						}
					}
				}
				extra_img4 {
					publicURL
					childImageSharp {
						sizes(quality: 100) {
							...GatsbyImageSharpSizes
						}
					}
				}
			}
		}
	}
`;
